/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlansCheckoutDetail_viewer = {
    readonly me: {
        readonly firstName: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"Experiments_viewer">;
    readonly " $refType": "PlansCheckoutDetail_viewer";
};
export type PlansCheckoutDetail_viewer$data = PlansCheckoutDetail_viewer;
export type PlansCheckoutDetail_viewer$key = {
    readonly " $data"?: PlansCheckoutDetail_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PlansCheckoutDetail_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlansCheckoutDetail_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Experiments_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'd3a7ee8d740596b202fe7355057882fe';
export default node;
