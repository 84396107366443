

import type { useDefaultPlansPricing_viewer } from '__generated__/useDefaultPlansPricing_viewer.graphql'
import type { usePricing_viewer } from '__generated__/usePricing_viewer.graphql'
import createPriceString from 'lib/createPriceString'
import type { Price } from 'lib/pricing/types'
import { isAnnual } from 'types/stripeConsts'

export const createPriceFromWebPaymentPlan = (
    webPaymentPlan:
        | usePricing_viewer['webPaymentPlan']
        | useDefaultPlansPricing_viewer['monthly']['secondaryWebPlan']
        | useDefaultPlansPricing_viewer['annual'],
): Price => {
    const { id, amount, currency, currencySymbol, interval, nickname } = webPaymentPlan
    const promotionApplied =
        'promotionApplied' in webPaymentPlan ? webPaymentPlan.promotionApplied : null

    return {
        id: id,
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        interval: interval,
        nickname: nickname,
        promotionApplied: {
            discountPct: promotionApplied?.stripeDiscount.discountPct || 0,
            discountAmount: promotionApplied?.stripeDiscount.discountAmount || 0,
            couponDurationInMonths: promotionApplied?.stripeDiscount.couponDurationInMonths || 0,
        },
        trialDays: promotionApplied?.trialDays || 0,
    }
}

export const getPaywallPrice = (priceObject: Price) => {
    const { interval } = priceObject
    const annualPlan = !!interval && isAnnual(interval.toLowerCase())

    const calculatedMonthlyPrice = createPriceString({
        // as we're showing this per month
        amount: priceObject.amount,
        currency: priceObject.currency,
        currencySymbol: priceObject.currencySymbol,
        stripeDiscountPct: null,
        stripeDiscountAmount: null,
        convertToMonth: annualPlan,
        hideCurrencyInfo: true,
    })

    return calculatedMonthlyPrice
}
