/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlanLayout_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"PlansCheckoutDetail_viewer" | "usePricing_viewer">;
    readonly " $refType": "PlanLayout_viewer";
};
export type PlanLayout_viewer$data = PlanLayout_viewer;
export type PlanLayout_viewer$key = {
    readonly " $data"?: PlanLayout_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PlanLayout_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "slug",
  "variableName": "slug"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "encryptedEmail"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlanLayout_viewer",
  "selections": [
    {
      "args": [
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "PlansCheckoutDetail_viewer"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "encryptedEmail",
          "variableName": "encryptedEmail"
        },
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "usePricing_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = 'e0de8b8cf358425c5f7442200da13830';
export default node;
