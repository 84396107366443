import { graphql, useFragment } from 'react-relay'

import type { usePricing_viewer$key } from '__generated__/usePricing_viewer.graphql'
import type { Price, Pricing } from 'lib/pricing/types'
import { createPriceFromWebPaymentPlan } from 'lib/pricing/utils'

export const usePricing = (viewer: usePricing_viewer$key): Pricing => {
    const { webPaymentPlan } = useFragment(usePricingFragment, viewer)

    if (webPaymentPlan.interval === 'year') {
        return {
            yearly: createPriceFromWebPaymentPlan(webPaymentPlan),
            monthly: undefined,
        }
    } else {
        return {
            monthly: createPriceFromWebPaymentPlan(webPaymentPlan),
            yearly: undefined,
        }
    }
}
// To ensure backwards compatibility when only one price interval is being returned
export const usePrice = (viewer: usePricing_viewer$key): Price => {
    const pricing = usePricing(viewer)

    return pricing.monthly || pricing.yearly
}

const usePricingFragment = graphql`
    fragment usePricing_viewer on Viewer
    @argumentDefinitions(encryptedEmail: { type: "String" }, slug: { type: "String" }) {
        webPaymentPlan(productSlug: $slug) {
            id
            amount
            currency
            currencySymbol
            interval
            nickname
            promotionApplied(slug: $slug) {
                trialDays
                stripeDiscount {
                    discountPct
                    discountAmount
                    couponDurationInMonths
                }
            }
        }
    }
`
